import axios from 'axios'
import { IQuoteForm } from 'components/assets/types'
import React, { FC, useEffect, useRef, useState } from 'react'
import QuoteFormItem from '../QuoteFormItem/QuoteFormItem'
import './FormRequestsTable.scss'
import { IoMdArrowDropdown } from 'react-icons/io'

interface FormRequestsTable {
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>
  setChatId: React.Dispatch<React.SetStateAction<string | number>>
  setChatSubject: React.Dispatch<React.SetStateAction<string>>
  chatId: string | number
}

const FormRequestsTable: FC<FormRequestsTable> = ({
  setChatOpen,
  setChatSubject,
  setChatId,
  chatId,
}) => {
  //state for table open
  const [open, setOpen] = useState(false)

  //state for all forms
  const [forms, setForms] = useState<IQuoteForm[]>([])

  //state for user
  const user = JSON.parse(localStorage.getItem('user') || '{}')

  //button ref
  const buttonRef = useRef<any>()

  //request for all forms
  useEffect(() => {
    axios
      .get('https://dev.ihaveatruck.ca/api/consumer-quote-requests', {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${user.access_token}`,
        },
      })
      .then((res) => {
        setForms(
          res.data.data.sort((x: IQuoteForm, y: IQuoteForm) => {
            return y.created_at - x.created_at
          })
        )
      })
  }, [])

  //handles close of qutes menu on click outside
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside)
    }
  }, [buttonRef])

  console.log('forms: ', forms)
  return (
    <div className="formRequestsTable-container">
      <button
        onClick={() => {
          setOpen(!open)
        }}
        className="formRequestsTable-container__button"
        ref={buttonRef}
      >
        view sent requests
        <IoMdArrowDropdown />
      </button>
      <div
        className="formRequestsTable-container__table"
        style={{ display: open ? 'grid' : 'none' }}
      >
        {forms ? (
          forms.map((form, key) => {
            return (
              <QuoteFormItem
                quote={form}
                key={key}
                setChatId={setChatId}
                setChatSubject={setChatSubject}
                setChatOpen={setChatOpen}
                chatId={chatId}
              />
            )
          })
        ) : (
          <p className="formRequestsTable-container__table__no-req">
            No Requests Yet
          </p>
        )}
      </div>
    </div>
  )
}

export default FormRequestsTable

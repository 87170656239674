import LogInPage from '../Forms/LogInPage'
import RegistrationPage from '../Forms/RegistrationPage'
import ResetPwd from '../Forms/ResetPwd'
import Chat from '../staticPages/Chat/Chat'
import Layout from '../UI/Layout/Layout'
import ProtectedLogin from '../UI/ProtectedLogin/ProtectedLogin'
import ProtectedRoute from '../UI/ProtectedRoute/ProtectedRoute'
import React, { useState, useEffect, useRef } from 'react'
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { AuthApi } from '../assets/AuthApi'
import { ILoginUser } from '../assets/types'
import RequestQuotePage from '../staticPages/RequestQuotePage/RequestQuotePage'
import axios from 'axios'

const PagesRoutes = () => {
  //state for header height
  const [headerHeight, setHeaderHeight] = useState<number>(0)

  const [error, setError] = useState<string>()

  //state for check logged user or not
  const [login, setLogin] = useState<ILoginUser>({
    id: 0,
    role_id: 0,
    email: '',
    first_name: '',
    last_name: '',
    is_active: 0,
    created_at: 0,
    updated_at: 0,
    access_token: '',
  })

  console.log(login)

  //state for remember me
  const [remember, setRemember] = useState<boolean>(false)

  //using= context of useAuth to use access provider
  const isAuthenticated = React.useContext(AuthApi)

  //set login-user from local storage  and auth to true
  useEffect(() => {
    const loggedInUser = localStorage.getItem('user')
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser)
      console.log(foundUser)
      isAuthenticated.setAuth(true)
      setLogin(foundUser)
      axios
        .get('https://dev.ihaveatruck.ca/api/item-categories', {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${foundUser.access_token}`,
          },
        })
        .then((res) => {
          isAuthenticated.setAuth(true)
          setLogin(foundUser)
        })
        .catch((err) => {
          setError(err.response.data.message)
          localStorage.removeItem('user')
          isAuthenticated.setAuth(false)
        })
    }
  }, [])

  //to user user
  const to_user = JSON.parse(localStorage.getItem('to_user') || '{}')

  //state for char open when small screen
  const [chatOpen, setChatOpen] = useState<boolean>(false)

  //state for chat id
  const [chatId, setChatId] = useState<number | string>(to_user.id)

  //state for chat subject
  const [chatSubject, setChatSubject] = useState<string>(to_user.subject)

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              setAuth={isAuthenticated.setAuth}
              auth={isAuthenticated.auth}
              setLogin={setLogin}
              headerHeight={headerHeight}
              setHeaderHeight={setHeaderHeight}
            />
          }
        >
          <Route path="/reset-password" element={<ResetPwd />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route
            path="/"
            element={
              <ProtectedLogin
                auth={isAuthenticated.auth}
                component={
                  <LogInPage
                    remember={remember}
                    setRemember={setRemember}
                    login={login}
                    setLogin={setLogin}
                    setAuth={isAuthenticated.setAuth}
                    auth={isAuthenticated.auth}
                    error={error}
                  />
                }
              />
            }
          />
          <Route
            path="/chat"
            element={
              <ProtectedRoute
                auth={isAuthenticated.auth}
                component={
                  <Chat
                    login={login}
                    headerHeight={headerHeight}
                    chatId={chatId}
                    chatOpen={chatOpen}
                    chatSubject={chatSubject}
                    setChatId={setChatId}
                    setChatSubject={setChatSubject}
                    setChatOpen={setChatOpen}
                  />
                }
              />
            }
          />
          <Route
            path="/requestQuote"
            element={
              <ProtectedRoute
                auth={isAuthenticated.auth}
                component={
                  <RequestQuotePage
                    login={login}
                    headerHeight={headerHeight}
                    setChatId={setChatId}
                    setChatSubject={setChatSubject}
                    setChatOpen={setChatOpen}
                  />
                }
              />
            }
          />
        </Route>
      </Routes>
    </>
  )
}

export default PagesRoutes

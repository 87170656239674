import React, { FC, useEffect, useState } from 'react'
import './InputQuoteForm.scss'
import DatePicker from 'react-datepicker'
import { ImCalendar } from 'react-icons/im'
import { BiTime } from 'react-icons/bi'
import { TiArrowSortedDown } from 'react-icons/ti'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import {
  IItemCategory,
  IItemDataInput,
  IQuoteForm,
} from 'components/assets/types'
import moment from 'moment'

interface inputQuoteFormProps {
  name: string
  type: string
  items: (string | number | undefined)[]
  field: string
  qouteData: IQuoteForm
  setQuoteData: React.Dispatch<React.SetStateAction<IQuoteForm>>
  preset?: IItemDataInput[]
  presetName?: string
  time?: moment.Moment
  setTime?: React.Dispatch<React.SetStateAction<moment.Moment>>
  startDate?: Date
  setStartDate?: React.Dispatch<React.SetStateAction<Date>>
  unit?: string
}

const InputQuoteForm: FC<inputQuoteFormProps> = ({
  name,
  type,
  items,
  field,
  qouteData,
  setQuoteData,
  preset,
  presetName,
  time,
  setTime,
  startDate,
  setStartDate,
  unit,
}) => {
  //state for button inside input
  const [open, setOpen] = useState<boolean>(false)
  //state for button inside input
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)

  //state for input data
  const [input, setInput] = useState<any>()

  //state for dropdown
  const [dropdownItem, setDropdownItem] = useState(items ? items[0] : '')

  //sets default item for dropdown on page load and input for dropdown
  useEffect(() => {
    if (!dropdownItem) {
      setDropdownItem(items && items.length ? items[0] : '')
    }

    if (items || qouteData.item_description_id == 0) {
      setInput(
        preset
          ? preset.find(
              (object) =>
                object[presetName as keyof typeof object] == dropdownItem
            )?.id
          : dropdownItem
      )
    }
  }, [items, dropdownItem])

  //set quote data to dropdown input
  useEffect(() => {
    setQuoteData({ ...qouteData, [field as keyof typeof qouteData]: input })
  }, [input])

  return (
    <div className="inputQuoteForm-container">
      <p className="inputQuoteForm-container__name">{name}</p>
      <button
        onClick={() => {
          setOpen(!open)
        }}
        className="inputQuoteForm-container__input"
        type="button"
      >
        {type == 'date' ? (
          <>
            <DatePicker
              dateFormat="dd-MM-yyyy"
              minDate={new Date()}
              selected={startDate}
              onChange={(date) => {
                setStartDate ? setStartDate(date ? date : new Date()) : ''
              }}
              dropdownMode="select"
              showMonthDropdown
              showYearDropdown
              adjustDateOnChange
              open={datePickerOpen}
            />
            <button
              className="inputQuoteForm-container__input__button"
              onClick={() => setDatePickerOpen(!open)}
              type="button"
            >
              <ImCalendar />
            </button>
          </>
        ) : type == 'time' ? (
          <>
            <TimePicker
              placeholder="Select time"
              defaultValue={time}
              use12Hours
              showSecond={false}
              focusOnOpen={true}
              format="hh:mm A"
              onChange={(e) => {
                console.log(qouteData)
                setTime ? setTime(e ? e : moment()) : ''
                setOpen(!open)
                setInput(time)
              }}
              open={open}
            />
            <button
              className="inputQuoteForm-container__input__button"
              onClick={() => setOpen(!open)}
              type="button"
            >
              <BiTime />
            </button>
          </>
        ) : type == 'dropdown' ? (
          <>
            <div className="inputQuoteForm-container__input__dropdown">
              <p className="inputQuoteForm-container__input__dropdown__title">
                {dropdownItem}{' '}
                {field == 'weight'
                  ? 'KG'
                  : field == 'length' || field == 'height' || field == 'width'
                  ? 'CM'
                  : ''}
              </p>
            </div>
            <button
              className="inputQuoteForm-container__input__button"
              onClick={() => setOpen(!open)}
              type="button"
            >
              <TiArrowSortedDown />
            </button>
          </>
        ) : (
          ''
        )}
      </button>
      {type == 'dropdown' ? (
        <div
          className="inputQuoteForm-container__input__dropdown__list"
          style={{ display: open ? 'flex' : 'none' }}
        >
          {items
            ? items.map((item, key) => (
                <button
                  key={key}
                  type="button"
                  className="inputQuoteForm-container__input__dropdown__list__item"
                  onClick={() => {
                    setDropdownItem(item)
                    setOpen(!open)
                    setInput(
                      preset
                        ? preset.find(
                            (object) =>
                              object[presetName as keyof typeof object] == item
                          )?.id
                        : item
                    )
                  }}
                >
                  {item}{' '}
                  {field == 'weight'
                    ? 'KG'
                    : field == 'length' || 'height' || 'width'
                    ? 'CM'
                    : ''}
                </button>
              ))
            : ''}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default InputQuoteForm

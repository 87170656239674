import axios from 'axios'
import { ILastMsg, IReceivedMessage } from 'components/assets/types'
import React, { FC, useEffect, useState } from 'react'
import './ChatUserBlock.scss'

interface ChatUserBlockProps {
  name: string
  photo: string
  online: boolean
  chatOpen: boolean
  id: number | string
  setChatId: React.Dispatch<React.SetStateAction<number | string>>
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>
  messages: ILastMsg[]
  subject: string
  setChatSubject: React.Dispatch<React.SetStateAction<string>>
}

const ChatUserBlock: FC<ChatUserBlockProps> = ({
  name,
  photo,
  online,
  chatOpen,
  id,
  setChatId,
  setChatOpen,
  messages,
  subject,
  setChatSubject,
}) => {
  //state for last message
  const [lastMessage, setLastMessage] = useState<ILastMsg>()

  //token
  const token = JSON.parse(localStorage.getItem('user') || '{}').access_token

  //current user
  const current_user = JSON.parse(localStorage.getItem('user') || '{}')

  //function that returns corrected name
  const correctedName = (name: string) => {
    const nameArr = name.split('')
    const newArr = []
    for (let i = 0; i <= 16; i++) {
      newArr.push(nameArr[i])
    }
    return `${newArr.join('')}${nameArr.length > newArr.length ? '...' : ''}`
  }

  //function that sets to_user in local storage
  const handleClick = () => {
    localStorage.setItem('to_user', JSON.stringify({ name: name, id: id }))
  }

  useEffect(() => {
    const newArr: ILastMsg[] = []
    const newMesages = messages.map((message) => {
      if (
        (message.subject == subject && message.user_id == id) ||
        (message.to_user_id == id && message.subject == subject)
      ) {
        newArr.push(message)
      }
    })
    setLastMessage(newArr[newArr.length - 1])
  }, [messages])

  return (
    <button
      className="chatUserBlock-container"
      onClick={() => {
        setChatOpen(true), setChatId(id), handleClick(), setChatSubject(subject)
      }}
    >
      <div className="chatUserBlock-container__avatar"></div>
      <div className="chatUserBlock-container__text">
        <p className="chatUserBlock-container__text__name">
          {correctedName(name)}
        </p>
        <p className="chatUserBlock-container__text__msg">
          {lastMessage?.content
            ? lastMessage.content.length > 20
              ? `${lastMessage.content.substring(0, 22)}...`
              : lastMessage.content
            : ''}
        </p>
      </div>
    </button>
  )
}

export default ChatUserBlock

import { ILoginUser } from 'components/assets/types'
import React, { FC } from 'react'
import { Outlet, Route } from 'react-router'
import Header from '../Header/Header'
import './Layout.scss'

interface LayoutProps {
  setAuth: React.Dispatch<React.SetStateAction<boolean>>
  auth: boolean
  setLogin: React.Dispatch<React.SetStateAction<ILoginUser>>
  headerHeight: number
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>
}

const Layout: FC<LayoutProps> = ({
  auth,
  setAuth,
  setLogin,
  headerHeight,
  setHeaderHeight,
}) => {
  return (
    <>
      <Header
        setAuth={setAuth}
        auth={auth}
        setLogin={setLogin}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Outlet />
    </>
  )
}

export default Layout

import React from 'react'
import { Navigate, useLocation } from 'react-router'

interface ProtectedLoginProps {
  component: JSX.Element
  rest?: any
  auth: boolean
}

export default function ProtectedLogin({
  component,
  auth,
  ...rest
}: ProtectedLoginProps) {
  if (auth) {
    const pathh = localStorage.getItem('url')
    return <Navigate to={pathh ? pathh : '/chat'} />
  } else {
    return component
  }
}

import React, { FC, useEffect, useRef, useState } from 'react'
import './Header.scss'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import Chat from 'components/staticPages/Chat/Chat'
import { ILoginUser, Size } from '../..//assets/types'

interface HeaderProps {
  setAuth: React.Dispatch<React.SetStateAction<boolean>>
  auth: boolean
  setLogin: React.Dispatch<React.SetStateAction<ILoginUser>>
  headerHeight: number
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>
}

const Header: FC<HeaderProps> = ({
  auth,
  setAuth,
  setLogin,
  headerHeight,
  setHeaderHeight,
}) => {
  const navigation: { name: string; to: string; func?: () => void }[] = auth
    ? [
        { name: 'Chat', to: '/chat' },
        { name: 'Request a Quote', to: '/requestQuote' },
        {
          name: 'Log out',
          to: '/',
          func: () => {
            setAuth(false)
            localStorage.clear()
            setLogin({
              id: 0,
              role_id: 0,
              email: '',
              first_name: '',
              last_name: '',
              is_active: 0,
              created_at: 0,
              updated_at: 0,
              access_token: '',
            })
          },
        },
      ]
    : [
        { name: 'Login', to: '/' },
        { name: 'SignUp', to: '/register' },
        { name: 'Reset Password', to: '/reset-password' },
      ]

  //ref for header
  const headerRef = useRef(null)

  //state for menu
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  // The size of the window
  const [size, setSize] = useState<Size>()

  // This function updates the state thus re-render components
  const resizeHanlder = () => {
    const width = window.innerWidth
    const height = window.innerHeight

    setSize({
      width: width,
      height: height,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHanlder)
    if (headerRef.current != null) {
      setHeaderHeight(headerRef.current['clientHeight'])
    }
    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', resizeHanlder)
    }
  }, [size])

  return (
    <header id="header" ref={headerRef}>
      <div className="container header-container">
        <div className="header-container__title">
          <Link to="/chat" className="header-container__title__text">
            IHAVEATRUCK.CA
          </Link>
        </div>
        <div className="header-container__navigation">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="header-container__navigation__button"
          >
            {menuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
          </button>
        </div>
        <div
          className={`header-container__menu ${
            menuOpen ? 'menu-open' : 'menu-closed'
          }`}
          style={{
            top: headerHeight,
            height: `calc(101vh - ${headerHeight}px)`,
          }}
        >
          {navigation.map((item, key) => (
            <Link
              key={key}
              to={item.to}
              onClick={() => {
                setMenuOpen(!menuOpen)
                item.func ? item.func() : ''
              }}
              className="header-container__menu__button"
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </header>
  )
}

export default Header

import axios from 'axios'
import {
  IItemCategory,
  IItemCharacteristics,
  IItemDataInput,
  ILoginUser,
  IMessageRequest,
  IQuoteForm,
} from 'components/assets/types'
import React, { FC, FormEvent, useEffect, useState } from 'react'
import InputQuoteForm from '../inputQuoteForm/InputQuoteForm'
import './RequestQuoteForm.scss'
import moment from 'moment'
import FormRequestsTable from '../FormRequestsTable/FormRequestsTable'

interface RequestQuoteFormProps {
  login: ILoginUser
  success: boolean
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>
  setChatId: React.Dispatch<React.SetStateAction<string | number>>
  setChatSubject: React.Dispatch<React.SetStateAction<string>>
}

const RequestQuoteForm: FC<RequestQuoteFormProps> = ({
  login,
  success,
  setSuccess,
  setChatOpen,
  setChatSubject,
  setChatId,
}) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  // state for request quote data
  const [quoteData, setQuoteData] = useState<IQuoteForm>({
    consumer_id: 0,
    desired_date_time: moment().unix(),
    item_description_id: 0,
    item_category_id: 0,
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    characteristic_setting_id: 0,
    created_at: 0,
    updated_at: 0,
    expand: 0,
  })

  //message request data
  const messageRequest = (created_at: any) => {
    const msgReq: IMessageRequest = {
      user_id: user.id,
      to_user_id: 1,
      content: `--New Quote-- 
      width: ${quoteData.width} 
      weight: ${quoteData.weight} 
      length: ${quoteData.length} 
      height: ${quoteData.height}`,
      subject: `${quoteData.desired_date_time}${created_at}`,
    }
    return msgReq
  }

  //console.log(quoteData.desired_date_time, quoteData.created_at + ' quote data')
  //token of user
  const token = JSON.parse(localStorage.getItem('user') || '{}').access_token

  const [descriptions, setDescriptions] = useState<IItemDataInput[]>([])

  const [categories, setCategories] = useState<IItemDataInput[]>([])

  const [characteristics, setCharacteristics] = useState<IItemCharacteristics>()

  useEffect(() => {
    getData(configGetDescriptions, setDescriptions)
    getData(configGetCategories, setCategories)
    getData(configGetCgaracteristics, setCharacteristics, 2)
  }, [])

  useEffect(() => {
    descriptions
      ? setQuoteData({ ...quoteData, item_description_id: descriptions[0]?.id })
      : ''
  }, [descriptions])

  //weights for weight input
  const [weights, setWeigts] = useState<number[]>([])

  const settingWeights = () => {
    if (characteristics !== undefined) {
      let temporaryWeights: number[] = []
      for (
        let i = characteristics.weight_minimum;
        i <= characteristics.weight_maximum;
        i += characteristics.weight_step
      ) {
        temporaryWeights.push(i)
      }
      setWeigts(temporaryWeights)
    }
  }
  //lengths for length input
  const [length, setLength] = useState<number[]>([])

  const settingLength = () => {
    if (characteristics !== undefined) {
      let temporaryLength: number[] = []
      for (
        let i = characteristics.length_minimum;
        i <= characteristics.length_maximum;
        i += characteristics.size_step
      ) {
        temporaryLength.push(i)
      }
      setLength(temporaryLength)
    }
  }
  //heights for height input
  const [height, setHeight] = useState<number[]>([])

  const settingHeight = () => {
    if (characteristics !== undefined) {
      let temporaryHeight: number[] = []
      for (
        let i = characteristics.height_minimum;
        i <= characteristics.height_maximum;
        i += characteristics.size_step
      ) {
        temporaryHeight.push(i)
      }
      setHeight(temporaryHeight)
    }
  }
  //widths for width input
  const [width, setWidth] = useState<number[]>([])

  const settingWidth = () => {
    if (characteristics !== undefined) {
      let temporaryHeight: number[] = []
      for (
        let i = characteristics.width_minimum;
        i <= characteristics.width_maximum;
        i += characteristics.size_step
      ) {
        temporaryHeight.push(i)
      }
      setWidth(temporaryHeight)
    }
  }

  //starts setting weights
  useEffect(() => {
    settingWeights()
    settingLength()
    settingHeight()
    settingWidth()
  }, [characteristics])

  //state for time
  const [time, setTime] = useState<moment.Moment>(moment())

  //state for date
  const [startDate, setStartDate] = useState<Date>(new Date())

  //state for desiredDateTime
  const [dateTime, setDateTime] = useState<string>('')

  //converts date and time to unixtimestamp
  useEffect(() => {
    const settingDateTime = async () => {
      setDateTime(
        //startDate.toISOString().split('T')[0] + ' ' + time.format('hh:mm:ss')
        (startDate.getYear() + 1900).toString() +
          '-' +
          (startDate.getMonth() + 1).toString() +
          '-' +
          startDate.getDate().toString() +
          ' ' +
          time.format('HH:mm')
      )
    }

    settingDateTime()
      .then(() => {
        if (dateTime !== '') {
          const convStrToDate = new Date(dateTime)
          const date = Math.floor(convStrToDate.getTime() / 1000)
          setQuoteData({ ...quoteData, desired_date_time: Number(date) })
        }
      })
      .catch((err) => console.log('setting time function error', err))
  }, [time, startDate, dateTime])

  //configuration for sending quote form request
  const config = {
    method: 'post',
    url: 'https://dev.ihaveatruck.ca/api/consumer-quote-requests',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${login.access_token}`,
    },
    data: quoteData,
  }

  //configuration for getting characteristics request
  const configGetCgaracteristics = {
    method: 'get',
    url: 'https://dev.ihaveatruck.ca/api/characteristic-settings',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${login.access_token}`,
    },
  }
  //configuration for getting descriptions request
  const configGetDescriptions = {
    method: 'get',
    url: 'https://dev.ihaveatruck.ca/api/item-descriptions',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${login.access_token}`,
    },
  }

  //configuration for getting item-categories request
  const configGetCategories = {
    method: 'get',
    url: 'https://dev.ihaveatruck.ca/api/item-categories',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${login.access_token}`,
    },
  }

  //get quotes preset function
  const getData = (
    configGet: {},
    setItems: React.Dispatch<React.SetStateAction<any>>,
    id?: number
  ) => {
    axios(configGet)
      .then((response) => {
        const { data } = response.data
        id
          ? setItems(data.find((item: IItemCharacteristics) => item.id == id))
          : setItems(data)
      })
      .catch((err) =>
        console.log(`Error of getPreset function: ${err.message}`)
      )
  }

  //config for message post request
  const configMessagePost = (created_at: any) => {
    return {
      method: 'post',
      url: 'https://dev.ihaveatruck.ca/api/messages',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: messageRequest(created_at),
    }
  }

  //function that send message
  const sendMessage = async (created_at: any) => {
    axios(configMessagePost(created_at))
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // handle submit function
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    axios(config)
      .then((res) => {
        const { data } = res.data
        setSuccess(true)
        sendMessage(res.data.data.created_at)
      })
      .catch((err) => {
        console.log(`error: ${err}`)
      })
  }

  return (
    <>
      <div className="requestQuoteForm-container">
        <h3 className="requestQuoteForm-container__title">
          Request for quote form
        </h3>
        <FormRequestsTable
          chatId={1}
          setChatId={setChatId}
          setChatSubject={setChatSubject}
          setChatOpen={setChatOpen}
        />
        <form
          onSubmit={handleSubmit}
          className="requestQuoteForm-container__form"
        >
          <InputQuoteForm
            items={[]}
            name="Date"
            type="date"
            qouteData={quoteData}
            setQuoteData={setQuoteData}
            time={time}
            setTime={setTime}
            field="desired_date_time"
            startDate={startDate}
            setStartDate={setStartDate}
          />
          <InputQuoteForm
            items={[]}
            name="Time"
            type="time"
            qouteData={quoteData}
            setQuoteData={setQuoteData}
            field=""
            setTime={setTime}
            time={time}
          />
          <InputQuoteForm
            name="Item description"
            type="dropdown"
            preset={descriptions}
            presetName={'text'}
            items={descriptions?.map((item) => item.text)}
            qouteData={quoteData}
            setQuoteData={setQuoteData}
            field="item_description_id"
          />
          <InputQuoteForm
            name="Item category"
            type="dropdown"
            preset={categories}
            items={categories?.map((item) => item.name)}
            qouteData={quoteData}
            presetName={'name'}
            setQuoteData={setQuoteData}
            field="item_category_id"
          />
          <div className="requestQuoteForm-container__form__characteristics">
            <InputQuoteForm
              name="Weight"
              type="dropdown"
              items={weights}
              qouteData={quoteData}
              setQuoteData={setQuoteData}
              field="weight"
            />
            <InputQuoteForm
              name="Length"
              type="dropdown"
              items={length}
              qouteData={quoteData}
              setQuoteData={setQuoteData}
              field="length"
            />
            <InputQuoteForm
              name="Height"
              type="dropdown"
              items={height}
              qouteData={quoteData}
              setQuoteData={setQuoteData}
              field="height"
            />
            <InputQuoteForm
              name="Width"
              type="dropdown"
              items={width}
              qouteData={quoteData}
              setQuoteData={setQuoteData}
              field="width"
            />
          </div>

          <button
            className="requestQuoteForm-container__form__submit-button button"
            type="submit"
          >
            Request for quote
          </button>
        </form>
      </div>
    </>
  )
}

export default RequestQuoteForm

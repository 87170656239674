import axios from 'axios'
import React, { FC, FormEvent, useState, useEffect } from 'react'
import { IForm, ILoginUser } from '../assets/types'
import './Form.scss'
import { AiOutlineCheck } from 'react-icons/ai'
import InputForm from '../UI/inputForm/inputForm'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'

interface LoginPageProps {
  setAuth: React.Dispatch<React.SetStateAction<boolean>>
  auth: boolean
  login: ILoginUser
  setLogin: React.Dispatch<React.SetStateAction<ILoginUser>>
  setRemember: React.Dispatch<React.SetStateAction<boolean>>
  remember: boolean
  error?: string
}

const LogInPage: FC<LoginPageProps> = ({
  login,
  setLogin,
  setAuth,
  error,
  auth,
  remember,
  setRemember,
}) => {
  //data for login post request
  const [loginForm, setLoginForm] = useState<IForm>({
    email: '',
    password: '',
  })
  console.log(loginForm)

  //state invalid pwd and email
  const [invalid, setInvalid] = useState<boolean>(false)

  //configuration for login post request
  const config = {
    method: 'post',
    url: 'https://dev.ihaveatruck.ca/api/auth/login',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    data: loginForm,
  }

  //sends post login request
  function handleSubmit(e: FormEvent) {
    e.preventDefault()
    axios(config)
      .then((res) => {
        const { data } = res.data
        localStorage.setItem('user', JSON.stringify(data))
        setLogin(data)
        setAuth(true)
        setInvalid(false)
      })
      .catch((err) => {
        console.log(`error: ${err}`)
        setInvalid(true)
      })
  }

  //submit form on enter
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      handleSubmit(event)
    }
  }

  return (
    <div className="form-container container">
      <div className="form-container__title">
        <h2>Customer Login</h2>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor magnam
          dicta nobis eius{' '}
        </p>
      </div>
      {invalid ? (
        <p className="form-container__error-msg">email or password incorrect</p>
      ) : (
        ''
      )}

      {error ? <p className="form-container__error-msg">{error}</p> : ''}
      <form onSubmit={handleSubmit} className="form-container__form">
        <InputForm
          setForm={setLoginForm}
          form={loginForm}
          button={false}
          name={'Username'}
          keyword={'email'}
        />
        <InputForm
          setForm={setLoginForm}
          form={loginForm}
          button={true}
          name={'Password'}
          keyword={'password'}
          visible={false}
        />
        <div className="form-container__form__buttons">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault()
              setRemember(!remember)
            }}
            className="form-container__form__buttons__checkbox button-text"
          >
            <div className="form-container__form__buttons__checkbox__box">
              {remember ? <AiOutlineCheck /> : ''}
            </div>
            <p className="form-container__form__buttons__checkbox__text">
              Remember me
            </p>
          </button>
          <Link
            className="form-container__form__buttons__button button-text"
            to="/reset-password"
          >
            Forgot password?
          </Link>
        </div>
        <button className="form-container__form__submit button" type="submit">
          Login
        </button>
        <div className="form-container__form__signup-btn">
          <p className="form-container__form__signup-btn__text">
            Doesnt't have an account?
          </p>
          <Link
            to="/register"
            className="form-container__form__submit__signup-btn__button link"
          >
            SIGNUP
          </Link>
        </div>
      </form>
    </div>
  )
}

export default LogInPage

import React, { FC } from 'react'
import './QuoteFormItem.scss'
import { IQuoteForm } from 'components/assets/types'
import { GoArrowSmallRight } from 'react-icons/go'
import { useNavigate } from 'react-router'

interface QuoteFormItemProps {
  quote: IQuoteForm
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>
  setChatId: React.Dispatch<React.SetStateAction<string | number>>
  setChatSubject: React.Dispatch<React.SetStateAction<string>>
  chatId: number | string
}

const QuoteFormItem: FC<QuoteFormItemProps> = ({
  quote,
  setChatOpen,
  setChatSubject,
  setChatId,
  chatId,
}) => {
  let created_at_date = new Date(quote.created_at * 1000)
  let desired_at_date = new Date(quote.desired_date_time * 1000)
  const date = (date: Date) => {
    const hoursTemp = date.getHours()
    const minutesTemp = date.getMinutes()
    const daysTemp = date.getDate()
    const monthTemp = date.getMonth()
    const hours =
      hoursTemp.toString().split('').length < 2 ? '0' + hoursTemp : hoursTemp
    const minutes =
      minutesTemp.toString().split('').length < 2
        ? '0' + minutesTemp
        : minutesTemp
    const days =
      daysTemp.toString().split('').length < 2 ? '0' + daysTemp : daysTemp
    const month =
      monthTemp.toString().split('').length < 2 ? '0' + monthTemp : monthTemp
    return `${days}:${month}/${hours}:${minutes}`
  }
  const created_at = `${date(created_at_date)}`
  const sheduled_at = `${date(desired_at_date)}`

  //navigate element
  const navigate = useNavigate()

  //function to select a chat
  const selectChat = async () => {
    const setChat = () => {
      const selected = JSON.parse(localStorage.getItem('selected') || '{}')
      setChatId(selected.chatId)
      setChatOpen(selected.chatOpen)
      setChatSubject(selected.chatSubject)
      navigate('/chat')
    }
    localStorage.setItem(
      'selected',
      JSON.stringify({
        chatId: chatId,
        chatSubject: `${quote.desired_date_time}${quote.created_at}`,
        chatOpen: true,
      })
    )

    await setChat()
  }

  return (
    <div className="quote-form-item-container">
      <p className="quote-form-item-container__item">Length: {quote.length}</p>
      <p className="quote-form-item-container__item">Width: {quote.width}</p>
      <p className="quote-form-item-container__item">Height: {quote.height}</p>
      <p className="quote-form-item-container__item">Weight: {quote.weight}</p>
      <p className="quote-form-item-container__item-time">
        Created at: <span>{created_at}</span>
      </p>
      <p className="quote-form-item-container__item-time">
        Sheduled to: <span>{sheduled_at}</span>
      </p>
      <button
        className="quote-form-item-container__button"
        onClick={() => selectChat()}
      >
        Go to chat <GoArrowSmallRight />
      </button>
    </div>
  )
}

export default QuoteFormItem

import React, { FC } from 'react'
import './ChatHeader.scss'
import { RiMenu3Line } from 'react-icons/ri'
import { IoIosArrowBack } from 'react-icons/io'

interface ChatHeaderProps {
  name: string
  online: boolean
  photo: string
  height: number
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ChatHeader: FC<ChatHeaderProps> = ({
  name,
  online,
  photo,
  height,
  setChatOpen,
}) => {
  const user = JSON.parse(localStorage.getItem('to_user') || '{}')
  return (
    <div className="chatHeader-container" style={{ height: `${height}px` }}>
      <div className="chatHeader-container__user">
        <button
          className="chatHeader-container__user__backToUsersBtn"
          onClick={() => setChatOpen(false)}
        >
          <IoIosArrowBack />
        </button>
        <div className="chatHeader-container__user__avatar">
          <img src="" alt="" />
        </div>
        <div className="chatHeader-container__user__info">
          <p className="chatHeader-container__user__info__name">{user.name}</p>
          <p className="chatHeader-container__user__info__online">
            {user && user.name ? 'online' : ''}
          </p>
        </div>
      </div>
      <button className="chatHeader-container__button">
        <RiMenu3Line />
      </button>
    </div>
  )
}

export default ChatHeader

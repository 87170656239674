import {
  IConfig,
  ILastMsg,
  ILoginUser,
  IMessageRequest,
  IReceivedMessage,
  IRecipient,
} from '../../assets/types'
import React, { FC, useEffect, useRef, useState } from 'react'
import './Chat.scss'
import ChatSidebar from '../../UI/ChatSidebar/ChatSidebar'
import ChatHeader from '../../UI/ChatHeader/ChatHeader'
import ChatContent from '../../UI/ChatContent/ChatContent'
import axios from 'axios'

interface ChatProps {
  login: ILoginUser
  headerHeight: number
  chatOpen: boolean
  chatId: string | number
  chatSubject: string
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>
  setChatId: React.Dispatch<React.SetStateAction<string | number>>
  setChatSubject: React.Dispatch<React.SetStateAction<string>>
}

const Chat: FC<ChatProps> = ({
  login,
  headerHeight,
  chatId,
  setChatId,
  chatSubject,
  setChatSubject,
  chatOpen,
  setChatOpen,
}) => {
  //current user
  const current__user = JSON.parse(localStorage.getItem('user') || ' {}')

  //setting path to current page
  useEffect(() => localStorage.setItem('url', '/chat'), [])

  //state for sidebar-width
  const [sidebarWidth, setSidebarWidth] = useState<number>(0)

  //state for sidebar header height
  const [chatHeaderHeight, setChatHeaderHeight] = useState<number>(0)

  //state for all messages between current_user and to_current_user
  const [messages, setMessages] = useState<IReceivedMessage[]>([])

  //token of user
  const token = JSON.parse(localStorage.getItem('user') || '{}').access_token

  //ref for chat window
  const chatWindowRef = useRef<any>()

  //fintion that scrolls chat to bootom
  const scrollChatToBottom = () => {
    chatWindowRef.current.scrollTo({
      top: chatWindowRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  //config for getting all messages to/from curr user
  const configGetAllMessages = {
    method: 'get',
    url: 'https://dev.ihaveatruck.ca/api/messages',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params: {
      user_id: `${chatId ? chatId : ''},${
        current__user.id ? current__user.id : ''
      }`,
      to_user_id: `${chatId ? chatId : ''}, ${
        current__user.id ? current__user.id : ''
      }`,
    },
  }

  //function to gett all messages
  const getAllMessages = () => {
    if (current__user.id && chatId !== undefined) {
      axios(configGetAllMessages)
        .then((response) => {
          if (chatId) {
            setMessages(
              response.data.data.sort(
                (x: IReceivedMessage, y: IReceivedMessage) =>
                  x.created_at - y.created_at
              )
            )
          }
        })
        .catch((error) => console.log(error))
    }
    if (chatId == undefined) {
      return
    }
  }

  //fulfill messages on page load
  useEffect(() => {
    getAllMessages()
  }, [chatId])
  //every 5 seconds checks database
  useEffect(() => {
    const intervalMessages = setInterval(getAllMessages, 5000)
    return () => {
      clearInterval(intervalMessages)
    }
  }, [chatId])

  useEffect(() => {
    //chat from request quote page
    const chat = JSON.parse(localStorage.getItem('selected') || '{}')
    if (chat.chatOpen == true) {
      setChatOpen(chat.chatOpen)
      setChatId(chat.chatId)
      setChatSubject(chat.chatSubject)
    }
  }, [])
  console.log(chatOpen, chatId, chatSubject, current__user.id, messages)

  return (
    <section id="chat">
      <div
        className="chat-container"
        style={{ minHeight: `calc(101vh - ${headerHeight}px)` }}
      >
        <div className="orange-block" />
        <div
          className="chat-container__module"
          style={{ top: `calc(${headerHeight}px + 50px)` }}
        >
          <ChatSidebar
            sidebarWidth={sidebarWidth}
            chatHeaderHeight={chatHeaderHeight}
            chatOpen={chatOpen}
            chatId={chatId}
            setSidebarWidth={setSidebarWidth}
            setChatHeaderHeight={setChatHeaderHeight}
            setChatOpen={setChatOpen}
            setChatId={setChatId}
            messages={messages}
            setChatSubject={setChatSubject}
          />
          <div
            style={{
              left: `${sidebarWidth}px`,
              width: `calc(100% - ${sidebarWidth}px)`,
            }}
            className="chat-container__module__contents"
          >
            <ChatHeader
              name={'Admin'}
              photo="photo"
              online={false}
              height={chatHeaderHeight}
              setChatOpen={setChatOpen}
            />
            <ChatContent
              headerHeight={chatHeaderHeight}
              to_user_id={chatId}
              subject={chatSubject}
              messages={messages}
              current__user={current__user.id}
              getAllMessages={getAllMessages}
              setMessages={setMessages}
              chatWindowRef={chatWindowRef}
              scrollChatToBottom={scrollChatToBottom}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Chat

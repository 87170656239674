import { IMessageProps } from 'components/assets/types'
import React, { FC } from 'react'
import './ChatToUserMessage.scss'
import { BsCheck2 } from 'react-icons/bs'
import { BsCheck2All } from 'react-icons/bs'

const ChatToUserMessage: FC<IMessageProps> = ({
  content,
  readedAt,
  createdAt,
}) => {
  let date = new Date(createdAt * 1000)
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const created_at = `${hours}:${
    minutes.toString().split('').length < 2 ? '0' + minutes : minutes
  }`

  return (
    <div className="ChatToUserMessage-container">
      <p className="ChatToUserMessage-container__text">{content}</p>
      <div className="ChatToUserMessage-container__subcontent">
        <p className="ChatUserMessage-container__subcontent__time">
          {created_at}
        </p>
        <div className="ChatUserMessage-container__subcontent__readAt">
          {readedAt ? <BsCheck2All /> : <BsCheck2 />}
        </div>
      </div>
    </div>
  )
}

export default ChatToUserMessage

import React, { FC, useEffect, useRef, useState } from 'react'
import './ChatSidebar.scss'
import { RiMenu3Line } from 'react-icons/ri'
import {
  IConfig,
  ILastMsg,
  IReceivedMessage,
  IRecipient,
  Size,
  subjectArr,
} from '../../assets/types'
import ava from '../../assets/images/soldier-boy.jpeg'
import ChatUserBlock from '../ChatUserBlock/ChatUserBlock'
import axios from 'axios'

interface ChatSidebarProps {
  sidebarWidth: number
  chatHeaderHeight: number
  chatOpen: boolean
  chatId: number | string
  setSidebarWidth: React.Dispatch<React.SetStateAction<number>>
  setChatHeaderHeight: React.Dispatch<React.SetStateAction<number>>
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>
  setChatId: React.Dispatch<React.SetStateAction<number | string>>
  messages: IReceivedMessage[]
  setChatSubject: React.Dispatch<React.SetStateAction<string>>
}

const ChatSidebar: FC<ChatSidebarProps> = ({
  sidebarWidth,
  chatHeaderHeight,
  chatOpen,
  chatId,
  setSidebarWidth,
  setChatHeaderHeight,
  setChatOpen,
  setChatId,
  setChatSubject,
  messages,
}) => {
  // The size of the window
  const [size, setSize] = useState<Size>()

  //subjects
  const [subjects, setSubjects] = useState<subjectArr[]>([])

  //state for all opened chats
  const [chatsIds, setChatsIds] = useState<IRecipient[]>([])

  //messages
  const [lastMessages, setLastMessages] = useState<ILastMsg[]>([])

  //current user
  const current_user = JSON.parse(localStorage.getItem('user') || '{}')

  // This function updates the state thus re-render components
  const resizeHanlder = () => {
    const width = window.innerWidth
    const height = window.innerHeight

    setSize({
      width: width,
      height: height,
    })
  }

  //token
  const token = JSON.parse(localStorage.getItem('user') || '{}').access_token

  useEffect(() => {
    const getChatIds = async () => {
      axios({
        method: 'get',
        url: 'https://dev.ihaveatruck.ca/api/messages/get-recipients',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => setChatsIds(res.data.data.recipients))
        .catch((err) => console.log(err))
    }

    getChatIds()
  }, [])

  //config for getting chats {
  const config = () => {
    return {
      method: 'get',
      url: 'https://dev.ihaveatruck.ca/api/messages',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        to_user_id: `${current_user.id},${chatsIds.map(
          (recipient) => `${recipient.user_id}`
        )}`,
        user_id: `${current_user.id},${chatsIds.map(
          (recipient) => `${recipient.user_id}`
        )}`,
      },
    }
  }

  const settingFirstMessages = async () => {
    if (chatsIds) {
      axios(config())
        .then((res) => {
          const sortedMessages = res.data.data.sort(
            (x: IReceivedMessage, y: IReceivedMessage) => {
              return x.created_at - y.created_at
            }
          )
          const messages = res.data.data

          const subjArr: subjectArr[] = []
          for (let i = 0; i < messages.length; i++) {
            let item = messages[i]
            let has = 0
            if (subjArr.length == 0) {
              subjArr.push({
                subject: item.subject,
                user_id: item.user_id,
                to_user_id: item.to_user_id,
                content: item.content,
              })
            }
            if (subjArr.length !== 0) {
              subjArr.map((subject: subjectArr) => {
                if (subject.subject !== item.subject) {
                  has += 1
                }
              })
            }

            if (has == subjArr.length) {
              subjArr.push({
                subject: item.subject,
                user_id: item.user_id,
                to_user_id: item.to_user_id,
                content: item.content,
              })
            }
          }
          setSubjects(subjArr)
          const lastMsg: string =
            sortedMessages[sortedMessages.length - 1].content
          setLastMessages(sortedMessages)
        })
        .catch((err) => console.log(err))
    }
  }

  useEffect(() => {
    settingFirstMessages()
  }, [chatsIds])

  //updates irst messages every 5 sec
  useEffect(() => {
    const setMessageInterval = setInterval(settingFirstMessages, 5000)
    return () => clearInterval(setMessageInterval)
  }, [chatsIds])

  console.log(lastMessages)

  //ref for header
  const sidebarRef = useRef(null)
  const headerHeightRef = useRef(null)

  useEffect(() => {
    window.addEventListener('resize', resizeHanlder)
    if (sidebarRef.current != null && headerHeightRef.current !== null) {
      setSidebarWidth(sidebarRef.current['clientWidth'])
      setChatHeaderHeight(headerHeightRef.current['clientHeight'])
    }
    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', resizeHanlder)
    }
  }, [size])

  console.log(chatsIds.map((item) => item.first_name) + ' chat ids')

  return (
    <div
      className={`chatSidebar-container ${
        chatOpen ? 'chatSidebar-sm-close' : ''
      }`}
      ref={sidebarRef}
    >
      <div className="chatSidebar-container__header" ref={headerHeightRef}>
        <div className="chatSidebar-container__header__avatar">
          <div style={{ backgroundImage: `url(${ava})` }} />
        </div>
        <button className="chatSidebar-container__header__button">
          <RiMenu3Line />
        </button>
      </div>
      <div className="chatSidebar-container__blocks">
        {subjects.map((subject, key) => {
          let elem: any
          chatsIds.map((chat) => {
            if (
              subject.to_user_id == chat.user_id ||
              subject.user_id == chat.user_id
            ) {
              elem = (
                <ChatUserBlock
                  name={`${chat.first_name} ${chat.last_name}`}
                  photo={'gg'}
                  online={false}
                  chatOpen={chatOpen}
                  id={chat.user_id}
                  key={key}
                  setChatId={setChatId}
                  setChatOpen={setChatOpen}
                  messages={lastMessages}
                  subject={subject.subject}
                  setChatSubject={setChatSubject}
                />
              )
            }
          })
          return elem
        })}
      </div>
    </div>
  )
}

export default ChatSidebar

import axios from 'axios'
import { IMessageRequest, IReceivedMessage } from 'components/assets/types'
import { stringify } from 'querystring'
import React, { FC, useEffect, useState } from 'react'
import ChatInput from '../ChatInput/ChatInput'
import ChatToUserMessage from '../ChatToUserMessage/ChatToUserMessage'
import ChatUserMessage from '../ChatUserMessage/ChatUserMessage'
import './ChatContent.scss'

interface chatContentProps {
  headerHeight: number
  to_user_id: number | string | undefined
  current__user: number
  messages: IReceivedMessage[]
  getAllMessages: () => void
  setMessages: React.Dispatch<React.SetStateAction<IReceivedMessage[]>>
  chatWindowRef: React.MutableRefObject<any>
  scrollChatToBottom: () => void
  subject: string
}

const ChatContent: FC<chatContentProps> = ({
  headerHeight,
  to_user_id,
  current__user,
  messages,
  getAllMessages,
  setMessages,
  chatWindowRef,
  scrollChatToBottom,
  subject,
}) => {
  //var to check if chat alreday overflowed at page load
  const [overflowed, setOverflowed] = useState<boolean>(false)
  //token
  const token = JSON.parse(localStorage.getItem('user') || '{}').access_token

  //scrolls chat to bottom on page load
  useEffect(() => {
    if (!overflowed) {
      const scroling = async () => {
        scrollChatToBottom()
        if (chatWindowRef.current.scrollHeight > 25) {
          setOverflowed(true)
        }
      }
      scroling()
    }
  }, [messages])

  return (
    <div
      style={{ height: `calc(100% - ${headerHeight}px)` }}
      className="chatContent-container"
    >
      <div className="chatContent-container__messages" ref={chatWindowRef}>
        {to_user_id !== 0 &&
          messages.map((message, key) => {
            if (
              message.user_id == current__user &&
              message.subject == subject
            ) {
              return (
                <ChatUserMessage
                  key={key}
                  content={message.content}
                  readedAt={message.read_at}
                  createdAt={message.created_at}
                />
              )
            }
            if (
              to_user_id !== 0 &&
              message.user_id == to_user_id &&
              message.subject == subject
            ) {
              return (
                <ChatToUserMessage
                  key={key}
                  content={message.content}
                  readedAt={message.read_at}
                  createdAt={message.created_at}
                />
              )
            }
          })}
      </div>
      <ChatInput
        to_user_id={to_user_id}
        user_id={current__user}
        token={token}
        getAllMessages={getAllMessages}
        setMessages={setMessages}
        scrollChatToBottom={scrollChatToBottom}
        subject={subject}
      />
    </div>
  )
}

export default ChatContent

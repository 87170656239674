import RequestQuoteForm from '../../UI/requestQuoteForm/RequestQuoteForm'
import React, { FC, useEffect, useState } from 'react'
import './RequestQuotePage.scss'
import { ILoginUser } from 'components/assets/types'
import { BsPatchCheck } from 'react-icons/bs'

interface RequestQuotePageProps {
  headerHeight: number
  login: ILoginUser
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>
  setChatId: React.Dispatch<React.SetStateAction<string | number>>
  setChatSubject: React.Dispatch<React.SetStateAction<string>>
}

const RequestQuotePage: FC<RequestQuotePageProps> = ({
  headerHeight,
  login,
  setChatOpen,
  setChatSubject,
  setChatId,
}) => {
  //state for success submiting form
  const [success, setSuccess] = useState<boolean>(false)

  //setting path to current page
  useEffect(() => {
    localStorage.setItem('url', '/requestQuote')
  }, [])

  return (
    <>
      <section id="requestQuote">
        {!success ? (
          <div
            className="requestQuote-container container"
            style={{ minHeight: `calc(101vh - ${headerHeight}px)` }}
          >
            <div className="requestQuote-container__text">
              <h1 className="requestQuote-container__text__title">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              </h1>
              <p className="requestQuote-container__text__subtitle">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo
                sequi possimus aut blanditiis placeat qui magnam rem fugiat.
                Praesentium nesciunt laborum harum ab tempora modi facere.
                Dolorem odio quae delectus!
              </p>
            </div>
            <RequestQuoteForm
              success={success}
              setSuccess={setSuccess}
              login={login}
              setChatId={setChatId}
              setChatSubject={setChatSubject}
              setChatOpen={setChatOpen}
            />
          </div>
        ) : (
          <div
            className="requestQuoteSuccess-container"
            style={{ minHeight: `calc(101vh - ${headerHeight}px)` }}
          >
            <div className="orange-block" />
            <div className="requestQuoteSuccess-container__content">
              <div className="requestQuoteSuccess-container__content__success-sign">
                <BsPatchCheck />
                <p className="requestQuoteSuccess-container__content__success-sign__text">
                  THANKYOU
                </p>
              </div>
              <div className="requestQuoteSuccess-container__content__descr">
                <p className="requestQuoteSuccess-container__content__descr__text">
                  We have received your application and will get back to you
                  soon
                </p>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default RequestQuotePage

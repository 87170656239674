import React, { FC, FormEvent, useRef, useState } from 'react'
import './ChatInput.scss'
import { RiSendPlaneFill } from 'react-icons/ri'
import { IMessageRequest, IReceivedMessage } from 'components/assets/types'
import axios from 'axios'

interface ChatInputProps {
  to_user_id: number | undefined | string
  user_id: number
  token: string
  getAllMessages: () => void
  setMessages: React.Dispatch<React.SetStateAction<IReceivedMessage[]>>
  scrollChatToBottom: () => void
  subject: string
}

const ChatInput: FC<ChatInputProps> = ({
  to_user_id,
  user_id,
  token,
  setMessages,
  getAllMessages,
  scrollChatToBottom,
  subject,
}) => {
  //ref for input
  const inputRef = useRef<HTMLInputElement>(null)
  //state for message
  const [message, setMessage] = useState('')

  //message request data
  const messageRequest: IMessageRequest = {
    user_id: user_id,
    to_user_id: to_user_id,
    content: message,
    subject: subject,
  }

  //config for message post request
  const config = {
    method: 'post',
    url: 'https://dev.ihaveatruck.ca/api/messages',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: messageRequest,
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (inputRef.current !== undefined && inputRef.current !== null) {
      inputRef.current.value = ''
    }
    if (message !== undefined) {
      axios(config)
        .then((res) => {
          console.log(res)
          setMessage('')
        })
        .then(() => {
          getAllMessages()
        })
        .then(() => {
          scrollChatToBottom()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <form className="chatInput-container" onSubmit={handleSubmit}>
      <input
        type="text"
        className="chatInput-container__input"
        placeholder="Write a message..."
        onChange={(e) => setMessage(e.target.value)}
        ref={inputRef}
        disabled={localStorage.getItem('to_user') ? '' : 'disabled'}
      />
      <button className="chatInput-container__button-submit" type="submit">
        <RiSendPlaneFill />
      </button>
    </form>
  )
}

export default ChatInput

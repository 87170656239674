import React, { useState, useEffect } from 'react'
import './App.css'
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { AuthApi } from './components/assets/AuthApi'
import PagesRoutes from './components/PagesRoutes/PagesRoutes'

function App() {
  //auth check
  const [auth, setAuth] = useState<boolean>(false)

  return (
    <>
      <AuthApi.Provider value={{ auth, setAuth }}>
        <Router>
          <PagesRoutes />
        </Router>
      </AuthApi.Provider>
    </>
  )
}

export default App

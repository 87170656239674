import React from 'react'
import { Navigate } from 'react-router'

interface ProtectedRouterProps {
  component: JSX.Element
  rest?: any
  auth: boolean
  path?: string
}

export default function ProtectedRoute({
  component,
  auth,
  path,
  ...rest
}: ProtectedRouterProps) {
  if (auth) {
    return component
  } else {
    return <Navigate to={'/'} />
  }
}

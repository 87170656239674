import React from 'react'

type AuthApiProps = {
  setAuth: React.Dispatch<React.SetStateAction<boolean>>
  auth: boolean
}

const initialAuth: AuthApiProps = {
  auth: false,
  setAuth: (): void => {
    throw new Error('setContext function must be overridden')
  },
}

export const AuthApi = React.createContext<AuthApiProps>(initialAuth)
